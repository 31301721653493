import React, { FunctionComponent } from "react";
import { Alert, Button, Stack } from "@mui/material";

export const GenericError: FunctionComponent = () => {
  return (
    <Stack spacing={2}>
      <Alert severity="error">
        Something went wrong&hellip; Try again, if you continue to have issues
        please contact{" "}
        <a href="mailto:partners@us.zip.co">Zip Partner Support</a>.
      </Alert>
      <Button
        variant="contained"
        sx={{ width: "100%", color: "white !important" }}
        onClick={() => window.location.reload()}
      >
        Refresh
      </Button>
    </Stack>
  );
};
