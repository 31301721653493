import { format } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";

export const getBrowserTimeZone = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const isSupportedIanaTimeZone = (
  supportedIanaTimeZoneNames: string[] | undefined,
  timeZone: string | null | undefined,
): boolean =>
  // Special case: if no timezones specified, allow all
  supportedIanaTimeZoneNames == null ||
  supportedIanaTimeZoneNames.length === 0 ||
  (!!timeZone &&
    timeZone !== null &&
    supportedIanaTimeZoneNames.indexOf(timeZone) > -1);

export const getOffsetForTimezone = (timeZoneName: string): string => {
  const offsetInMilliseconds = getTimezoneOffset(timeZoneName);
  const offsetInHours = Math.floor(offsetInMilliseconds / (60 * 60 * 1000));
  return offsetInHours <= 0 ? offsetInHours.toString() : `+${offsetInHours}`; // Force `+` prefix for offset
};

export const startOfUtcDay = (input: Date): Date => {
  const dateString = format(input, "yyyy-MM-dd");
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));
};
