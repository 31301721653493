export const DEFAULT_TIMEZONE = "America/New_York";

export type TimeZoneContextState = {
  supportedIanaTimeZones: string[];
  timeZoneId: string;
};

export type TimeZoneActions = {
  setTimeZone: (_timeZone: string) => void;
};

export type TimeZoneContextType = TimeZoneContextState & TimeZoneActions;

export type ModeActions = { type: "SET_TIMEZONE"; payload: string };
