import { useContext } from "react";
import { AxiosContext } from "../contexts/api/AxiosContext";

export const useHttpClientReady = (): boolean => {
  const context = useContext(AxiosContext);
  if (!context) {
    throw new Error("Attempted to retrieve state without a context");
  }

  return context.clientReady;
};
