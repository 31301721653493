import { createInstance, enums } from "@optimizely/react-sdk";

const optimizelySdkKey: string =
  process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY ?? "";

const logLevel = process.env.NEXT_PUBLIC_ENV === 'development' ? enums.LOG_LEVEL.NOTSET : enums.LOG_LEVEL.ERROR;

export const optimizelyClient = createInstance({
  sdkKey: optimizelySdkKey,
  logLevel: logLevel,
});
