import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  Suspense,
} from "react";
import { CircularProgress, Stack } from "@mui/material";

export interface LoaderProps {
  loading?: boolean;
  fallback?: ReactNode;
}

export const ProgressLoader = (
  <Stack alignItems="center">
    <CircularProgress />
  </Stack>
);

export const Loader: FunctionComponent<PropsWithChildren<LoaderProps>> = ({
  loading,
  children,
  fallback = ProgressLoader,
}) => {
  return (
    <Suspense fallback={fallback} data-testid="loader">
      {loading ? fallback : children}
    </Suspense>
  );
};
