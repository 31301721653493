import { IdToken, useAuth0 } from "@auth0/auth0-react";
import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  PropsWithChildren,
} from "react";
import { User, UserContext } from "merchant-core";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const UserProvider = ({ children }: PropsWithChildren) => {
  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0<User>();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [idToken, setIdToken] = useState<IdToken | undefined>();

  const getAuthTokens = useCallback((): void => {
    if (user) {
      const getTokens = async () => {
        const token = await getAccessTokenSilently();
        const idTokenClaims = await getIdTokenClaims();
        setAccessToken(token);
        setIdToken(idTokenClaims);
      };

      getTokens().catch(console.error);
    }
  }, [user, getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    if (!accessToken) {
      getAuthTokens();
    }
  }, [accessToken, getAuthTokens]);

  const valueMemo = useMemo(() => {
    return {
      user,
      accessToken,
      idToken,
      refreshAccessToken: getAuthTokens,
    };
  }, [user, accessToken, idToken, getAuthTokens]);

  return (
    <UserContext.Provider value={valueMemo}>{children}</UserContext.Provider>
  );
};
