import "../styles/globals.css";
import "../fonts/fonts.css";
import type { AppProps } from "next/app";
import { CacheProvider } from "@emotion/react";
import FullStory from "react-fullstory";
import CssBaseline from "@mui/material/CssBaseline";
import Router from "next/router";
import { Header } from "../components/Header";
import { OptimizelyProviderWrapper } from "optimizely";
import { SnackbarProvider } from "notistack";
import Head from "next/head";
import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { UserProvider } from "../contexts/user/UserProvider";
import {
  ContentfulClient,
  ContentfulClientInterface,
  ContentfulProvider,
} from "react-contentful";
import {
  Auth0Provider,
  AppState,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { MerchantUserProvider } from "../contexts/merchantUser/MerchantUserProvider";
import { createEmotionCache } from "../utils/emotion";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SegmentProvider } from "../contexts/segment/SegmentProvider";
import { DatadogProvider } from "../contexts/datadog/DatadogProvider";
import { StylesProvider } from "../contexts/styles/StylesProvider";
import { useHttpClientReady } from "../hooks/useHttpClientReady";
import { useMerchantUser } from "../hooks/useMerchantUser";
import { appWithTranslation, useTranslation } from "next-i18next";
import { AxiosProvider } from "../contexts/api/AxiosProvider";
import { SessionExpiryModal } from "../components/SessionExpiryModal";
import { SettingsProvider } from "../contexts/settings/SettingsProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DebugLogProvider } from "../contexts/logging/DebugLogProvider";
import { ClientProvider } from "../contexts/client/ClientProvider";
import {
  User,
  TimeZoneProvider,
  isPublicRoute,
  LocalStorageProvider,
} from "merchant-core";

interface ContentfulProviderWrapperProps {
  client: ContentfulClientInterface;
  children?: ReactNode;
}

const ContentfulProviderWrapper: FunctionComponent<
  ContentfulProviderWrapperProps
> = ({ client, children }): JSX.Element => {
  // @ts-ignore -- ContentfulProvider uses a stale version of React types.
  return <ContentfulProvider client={client}>{children}</ContentfulProvider>;
};

const DEFAULT_QUERY_CACHE_BEHAVIOR = 5 * 60 * 1000; // 5 minutes

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_QUERY_CACHE_BEHAVIOR, // The time in milliseconds after data is considered stale.
      cacheTime: DEFAULT_QUERY_CACHE_BEHAVIOR, // The time in milliseconds that unused/inactive cache data remains in memory.
      retry: false,
    },
  },
});

const clientSideEmotionCache = createEmotionCache();
const contentfulClient: ContentfulClientInterface = ContentfulClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE || "",
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_API_TOKEN || "",
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
});

type AppPropsWithCache = AppProps & {
  emotionCache: typeof clientSideEmotionCache;
  user?: User;
};

const onRedirectCallback = (appState?: AppState | undefined) => {
  const targetPath = appState?.returnTo || "/";
  Router.replace(targetPath, targetPath);
};

const App = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppPropsWithCache): JSX.Element => {
  const publicRoute = isPublicRoute(Component);
  if (publicRoute) {
    return (
      <UnauthenticatedContent
        Component={Component}
        {...pageProps}
        emotionCache={emotionCache}
      />
    );
  }

  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ""}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ""}
      audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
      scope={process.env.NEXT_PUBLIC_AUTH0_SCOPE}
      redirectUri={
        typeof window !== "undefined" ? window.location.origin : undefined
      }
      onRedirectCallback={onRedirectCallback}
    >
      <UserProvider>
        <MerchantUserProvider>
          <ClientProvider>
            <AxiosProvider>
              <AuthenticatedContent
                Component={Component}
                {...pageProps}
                emotionCache={emotionCache}
              />
            </AxiosProvider>
          </ClientProvider>
        </MerchantUserProvider>
      </UserProvider>
    </Auth0Provider>
  );
};

export default appWithTranslation(App) as unknown as JSX.Element;

export function AppContent({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppPropsWithCache): JSX.Element {
  const { t } = useTranslation("common");
  const { merchantId } = useMerchantUser();
  const appName = t("appName");

  const tabTitle = useMemo((): string => {
    if (
      process.env.NEXT_PUBLIC_ENVIRONMENT_LABEL &&
      process.env.NEXT_PUBLIC_ENVIRONMENT_LABEL !== "Production"
    ) {
      return `${appName} - ${process.env.NEXT_PUBLIC_ENVIRONMENT_LABEL}`;
    }
    return appName;
  }, [appName]);

  const clientReady = useHttpClientReady();
  if (!clientReady) {
    return <></>;
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <LocalStorageProvider merchantId={merchantId}>
          <SettingsProvider>
            <DebugLogProvider>
              <DatadogProvider>
                <SegmentProvider>
                  <FullStory
                    org={process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID || ""}
                  />
                  <OptimizelyProviderWrapper userId={merchantId}>
                    <ContentfulProviderWrapper client={contentfulClient}>
                      <CacheProvider value={emotionCache}>
                        <SnackbarProvider
                          maxSnack={3}
                          autoHideDuration={5000}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <TimeZoneProvider supportedIanaTimeZones={[]}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <StylesProvider>
                                <SessionExpiryModal />
                                <Header />
                                <CssBaseline />
                                <Head>
                                  <title>{tabTitle}</title>
                                </Head>
                                <Component {...pageProps} />
                              </StylesProvider>
                            </LocalizationProvider>
                          </TimeZoneProvider>
                        </SnackbarProvider>
                      </CacheProvider>
                    </ContentfulProviderWrapper>
                  </OptimizelyProviderWrapper>
                </SegmentProvider>
              </DatadogProvider>
            </DebugLogProvider>
          </SettingsProvider>
        </LocalStorageProvider>
      </QueryClientProvider>
    </>
  );
}

const UnauthenticatedContent = ({
  Component,
  pageProps,
  emotionCache,
}: AppPropsWithCache): JSX.Element => {
  const { t } = useTranslation("common");
  const appName = t("appName");

  const tabTitle = useMemo((): string => {
    if (
      process.env.NEXT_PUBLIC_ENVIRONMENT_LABEL &&
      process.env.NEXT_PUBLIC_ENVIRONMENT_LABEL !== "Production"
    ) {
      return `${appName} - ${process.env.NEXT_PUBLIC_ENVIRONMENT_LABEL}`;
    }
    return appName;
  }, [appName]);

  return (
    <ClientProvider>
      <CacheProvider value={emotionCache}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <TimeZoneProvider supportedIanaTimeZones={[]}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StylesProvider>
                <CssBaseline />
                <Head>
                  <title>{tabTitle}</title>
                </Head>
                <Component {...pageProps} />
              </StylesProvider>
            </LocalizationProvider>
          </TimeZoneProvider>
        </SnackbarProvider>
      </CacheProvider>
    </ClientProvider>
  );
};

const AuthenticatedContent = withAuthenticationRequired(AppContent);
