import { datadogRum, RumGlobal } from "@datadog/browser-rum";
import { useContext } from "react";
import { DatadogContext } from "../contexts/datadog/DatadogContext";

export const useDatadog = (): RumGlobal | null => {
  const context = useContext(DatadogContext);
  if (!context) {
    throw new Error(
      "Attempted to retrieve datadog without a context. Ensure DatadogProvider has been applied.",
    );
  }

  if (context.isEnabled) {
    return datadogRum;
  }

  return null;
};
