import { createContext } from "react";
import { EventName } from "../../constants/events";

export const consoleLogger = (
  eventName: EventName | string,
  properties?: Record<string, unknown>,
): void => {
  try {
    console.log(eventName, properties);
  } catch {}
};

export const nullLogger = (
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  eventName: EventName | string,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  properties?: Record<string, unknown>,
): void => {};

export interface DebugLogger {
  log: (
    // eslint-disable-next-line no-unused-vars
    eventName: EventName | string,
    // eslint-disable-next-line no-unused-vars
    properties?: Record<string, unknown>,
  ) => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DebugLogContext = createContext<DebugLogger>({ log: nullLogger });
