import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { TimeZoneContext } from "./TimeZoneContext";
import {
  DEFAULT_TIMEZONE,
  TimeZoneContextState,
  TimeZoneContextType,
} from "./TimeZoneContext.types";
import { timeZoneReducer } from "./TimeZoneReducer";
import {
  getBrowserTimeZone,
  isSupportedIanaTimeZone,
} from "../../utils/timezone";
import { LocalStorageContext } from "../localStorage/LocalStorageContext";

export const TIMEZONE_STORAGE_KEY = "TIMEZONE_ID";

export type TimezoneProviderProps = {
  supportedIanaTimeZones: string[];
};

export const TimeZoneProvider = ({
  supportedIanaTimeZones,
  children,
}: PropsWithChildren<TimezoneProviderProps>) => {
  const { localStorageGet, localStorageSet } = useContext(LocalStorageContext);

  const initialState: TimeZoneContextState = useMemo(() => {
    const storedTimeZoneId = !localStorageGet
      ? null
      : localStorageGet(TIMEZONE_STORAGE_KEY);
    const browserTimeZoneId = getBrowserTimeZone();

    const firstApplicableTimeZone = isSupportedIanaTimeZone(
      supportedIanaTimeZones,
      storedTimeZoneId,
    )
      ? storedTimeZoneId
      : isSupportedIanaTimeZone(supportedIanaTimeZones, browserTimeZoneId)
        ? browserTimeZoneId
        : null;

    return {
      supportedIanaTimeZones: supportedIanaTimeZones || [],
      timeZoneId: firstApplicableTimeZone || DEFAULT_TIMEZONE,
    };
  }, [localStorageGet, localStorageSet, supportedIanaTimeZones]);

  const [state, dispatch] = useReducer(timeZoneReducer, initialState);

  const setTimeZone = useCallback((timeZone: string) => {
    dispatch({ type: "SET_TIMEZONE", payload: timeZone });
  }, []);

  const timeZoneValue: TimeZoneContextType = useMemo(() => {
    return {
      timeZoneId: state.timeZoneId,
      supportedIanaTimeZones: state.supportedIanaTimeZones,
      setTimeZone,
    };
  }, [state.timeZoneId, state.supportedIanaTimeZones]);

  useEffect(() => {
    const storedTimeZoneId = localStorageGet(TIMEZONE_STORAGE_KEY);
    if (storedTimeZoneId !== state.timeZoneId) {
      localStorageSet(TIMEZONE_STORAGE_KEY, state.timeZoneId);
    }
  }, [localStorageGet, localStorageSet, state.timeZoneId]);

  return (
    <TimeZoneContext.Provider value={timeZoneValue}>
      {children}
    </TimeZoneContext.Provider>
  );
};
