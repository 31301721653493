import {
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  FunctionComponent,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";
import { useSession } from "../hooks/useSession";
import styled from "styled-components";
import { colors } from "merchant-core";

const DialogTitleCustomized = styled(DialogTitle)`
  border-bottom: 2px solid ${colors.BORDER_GREY};
  font-size: 0.9rem;
  padding: 5px 15px !important;
  background: ${colors.PURPLE_LIGHTEST};
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    padding: 0px;
    margin: 0px;
  }
`;

export interface ModalProps {
  title?: string;
  show: boolean;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

interface ModalHeaderProps {
  title?: string;
}

const ModalHeader: FunctionComponent<ModalHeaderProps> = ({ title }) => {
  return (
    <DialogTitleCustomized sx={{ m: 0, p: 2 }}>
      <Typography variant="h3" sx={{ p: 2 }}>
        {title}
      </Typography>
    </DialogTitleCustomized>
  );
};

export const ModalCustom: FunctionComponent<PropsWithChildren<ModalProps>> = ({
  title,
  show,
  children,
  fullWidth = true,
  maxWidth = "lg",
}) => {
  return (
    <Dialog open={show} fullWidth={fullWidth} maxWidth={maxWidth}>
      <ModalHeader title={title} />
      <DialogContent style={{ paddingTop: "30px" }}>{children}</DialogContent>
    </Dialog>
  );
};

export const SessionExpiryModal: FunctionComponent = () => {
  const { sessionExpiringSoon, tokenExpired, refreshAccessToken, logout } =
    useSession();
  const [sessionConfirmed, setSessionConfirmed] = useState(false);

  const buttonContent = sessionConfirmed ? (
    <CircularProgress size={24} color="secondary" />
  ) : (
    <>Continue</>
  );

  useEffect(() => {
    if (sessionConfirmed) {
      refreshAccessToken();
      setSessionConfirmed(false);
    }
  }, [sessionConfirmed, refreshAccessToken]);

  useEffect(() => {
    if (tokenExpired) {
      logout();
    }
  }, [tokenExpired, logout]);

  return (
    <ModalCustom title="Session Expiring" show={sessionExpiringSoon}>
      <Typography>
        Your session is expiring soon! Click this button to continue browing.
        You will be logged out soon otherwise.
      </Typography>
      <Button variant="contained" onClick={() => setSessionConfirmed(true)}>
        {buttonContent}
      </Button>
    </ModalCustom>
  );
};
