import TextField from "@mui/material/TextField";
import React from "react";
import styled from "styled-components";
import { borderRadius } from "../themes/borders";
import { colors } from "../themes/colors";

export interface TextFieldEvent {
  target: {
    value: string;
  };
}

export const StyledTextField = styled((props) => (
  <TextField
    fullWidth
    variant="outlined"
    size="medium"
    {...props}
    InputProps={{
      style: {
        borderRadius,
      },
      ...props.InputProps,
    }}
    sx={{
      background: colors.BACKGROUND_GREY,
      style: {
        borderRadius,
        ...props.sx?.style,
      },
      ...props.sx,
    }}
  />
))``;
