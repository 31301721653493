import { createContext } from "react";

export type SettingsState = {
  isDevelopmentMode: boolean;
};

export type ApplicationSettings = SettingsState & {
  toggleDevelopmentMode: () => void;
};

export const SettingsContext = createContext<ApplicationSettings>(
  {} as ApplicationSettings,
);
