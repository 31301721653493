import { Theme, createTheme } from "@mui/material/styles";
import "@emotion/styled";
import { colors } from "merchant-core";
import { onboardingHubTheme } from "./onboardingHub";
import { enUS } from "@mui/x-date-pickers/locales";

export const themeLight: Theme = createTheme({
  ...onboardingHubTheme,
  palette: {
    mode: "light",
    primary: {
      main: colors.PURPLE_DARKEST.toString(),
    },
    secondary: {
      main: colors.PURPLE_MEDIUM.toString(),
    },
    error: {
      main: colors.RED_ERROR.toString(),
    },
    background: {
      default: colors.BACKGROUND_GREY.toString(),
      paper: colors.PURE_WHITE.toString(),
    },
    text: {
      primary: colors.PURPLE_DARKEST.toString(),
      secondary: colors.PURPLE_MEDIUM.toString(),
      disabled: colors.PURPLE_LIGHT.toString(),
    },
  },
}, enUS);
