import { useContext } from "react";
import { ApplicationSettings, SettingsContext } from "../contexts/settings/SettingsContext";

export const useSettings = (): ApplicationSettings => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error("Attempted to retrieve settings without a context. Ensure SettingsProvider has been applied.");
  }

  return {
    isDevelopmentMode: context.isDevelopmentMode,
    toggleDevelopmentMode: context.toggleDevelopmentMode,
  };
};
