import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";
import {
  MerchantUserContext,
  MerchantUserContextState,
} from "./MerchantUserContext";
import {
  getDefaultOnboardingMerchantId,
  getDefaultMerchantId,
  getOnboardingMerchantIds,
  OnboardingMerchantId,
  useUser,
} from "merchant-core";

export const MerchantUserProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const user = useUser();
  const [onboardingMerchantId, setOnboardingMerchantId] = useState<OnboardingMerchantId>("");

  const valueMemo = useMemo(() => {
    const omId = onboardingMerchantId || getDefaultOnboardingMerchantId(user) || "";
    const onboardingMerchantIds = getOnboardingMerchantIds(user) || [];
    const onboardedMerchantId = getDefaultMerchantId(user) || "";
    const isOnboardedMerchantUser = !omId && !!onboardedMerchantId;

    return {
      merchantId: omId,
      merchantIds: onboardingMerchantIds,
      isOnboardedMerchantUser,
    };
  }, [user, onboardingMerchantId]);

  const updateMerchantId = useCallback((merchantId: OnboardingMerchantId) => {
    if (merchantId === valueMemo.merchantId) {
      return;
    }

    if (!valueMemo.merchantIds.some(mid => mid === merchantId)) {
      throw new Error(`An unknown merchant ID was provided: ${merchantId}. This is a bug!`);
    }

    setOnboardingMerchantId(merchantId);
  }, [valueMemo.merchantId, valueMemo.merchantIds]);

  const contextState = useMemo<MerchantUserContextState>(
    () => ({
      user,
      merchantId: valueMemo.merchantId,
      merchantIds: valueMemo.merchantIds,
      setOnboardingMerchantId: updateMerchantId
    }),
    [user, valueMemo, updateMerchantId],
  );

  // hard redirect, this is an onboarded merchant user that should be in the merchant portal
  const merchantPortalUrl = process.env.NEXT_PUBLIC_MERCHANT_PORTAL_URL;
  if (valueMemo.isOnboardedMerchantUser && merchantPortalUrl) {
    window.location.href = merchantPortalUrl;
  }

  return (
    <MerchantUserContext.Provider value={contextState}>
      {children}
    </MerchantUserContext.Provider>
  );
};
