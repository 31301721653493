import { User as Auth0User } from "@auth0/auth0-react";

export const roleClaimName = "https://quadpay.com/roles";
export const territoryClaimName =
  "http://schemas.quadpay.com/ws/2017/claims/territories";
export const onboardingClaimName =
  "http://schemas.quadpay.com/ws/2017/claims/merchantonboarding";
export const merchantIdClaimName =
  "http://schemas.quadpay.com/ws/2017/claims/merchantid";

export interface User extends Auth0User {
  name: string;
  nickname: string;
  picture: string;
  email: string;
  email_verified: boolean;

  given_name?: string;
  family_name?: string;

  [roleClaimName]: UserPermission[];
  [territoryClaimName]: TerritoryMetadata[];
  [onboardingClaimName]: TerritoryMetadata[];
  [merchantIdClaimName]?: string;
}

export interface TerritoryMetadata {
  territory: string;
  merchant_id?: string;
  merchant_ids?: string[];
}

export type UserPermission =
  | "mp-administrator"
  | "mp-recon-transactions"
  | "mp-recon-payments"
  | "mp-manage-refunds"
  | "mp-manage-disputes"
  | "mp-manage-settings";
