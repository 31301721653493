import { themeLight } from "../../themes/themeLight";
import { themeDark } from "../../themes/themeDark";
import { StyleState } from "./StylesContext";

type ModeActions = { type: "TOGGLE_THEME" };

export const stylesReducer = (state: StyleState, action: ModeActions): StyleState => {
  switch (action.type) {
    case "TOGGLE_THEME":
      const newMode = state.mode === "light" ? "dark" : "light"
      return {
        ...state,
        mode: newMode,
        isLightMode: newMode === "light",
        theme: newMode === "light" ? themeLight : themeDark,
      };
    default:
      return state;
  }
};