import { MenuItem, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { useSession } from "../hooks/useSession";

export const LogoutMenuItem: FunctionComponent = () => {
  const { logout } = useSession();

  return (
    <MenuItem onClick={() => logout()}>
      <Typography>Logout</Typography>
    </MenuItem>
  );
};
