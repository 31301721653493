import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { colors } from "../themes/colors";

export type DataItem = {
  [key: string]: string | number | Date | null | undefined;
};

export interface DebugTableProps<T extends DataItem> {
  data?: T[];
}

const toSentenceCase = (str: string): string => {
  return str
    .replace(/([A-Z])/g, " $1") // Add space before capital letters
    .replace(/[_-]/g, " ") // Replace underscores and hyphens with spaces
    .toLowerCase() // Convert to lower case
    .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
};

export const DebugTable = <T extends DataItem>({
  data,
}: DebugTableProps<T>) => {
  if (!data?.length) {
    return <Typography>No data available</Typography>;
  }

  const columns = Object.keys(data[0]) as (keyof T)[];

  const fontSize = "10px";

  return (
    <TableContainer sx={{ height: "100%", maxHeight: "100%", scrollY: "auto" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={String(column)}
                sx={{ backgroundColor: colors.PURPLE_LIGHTEST, fontSize }}
              >
                {toSentenceCase(column.toString())}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column) => (
                <TableCell key={String(column)} sx={{ fontSize }}>
                  {row[column] instanceof Date
                    ? format(row[column] as Date, "EEE yyyy-MM-dd")
                    : (row[column]?.toString() ?? "-")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
