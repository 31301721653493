import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import AppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import {
  Button,
  Menu,
  Typography,
  Box,
  Alert,
  Divider,
  MenuItem,
  Stack,
} from "@mui/material";
import { LogoutMenuItem } from "./LogoutMenuItem";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useSettings } from "../hooks/useSettings";
import { useMerchantUser } from "../hooks/useMerchantUser";
import { MerchantSwitcherMenu } from "./MerchantSwitcherMenu";
import { useDadJoke } from "../hooks/useDadJoke";
import { useStyles } from "../hooks/useStyles";
import Image from "next/image";
import zipLogo from "../public/images/logo.svg";
import { Gravatar, colors, getInitials, useIdToken } from "merchant-core";

export interface MenuItem {
  route: string;
  text: string;
  show: boolean;
}

const AppBarCustomized = styled(AppBar)<AppBarProps>`
  border-bottom: 1px solid ${colors.PURPLE_LIGHTEST};
`;

export const UserInfoMenuItem: FunctionComponent = (): JSX.Element => {
  const { first_name, email } = useIdToken();
  const displayName = first_name || email || "";

  return (
    <MenuItem>
      <Stack>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Account: {displayName}
        </Typography>
      </Stack>
    </MenuItem>
  );
};

export const Header: FunctionComponent = () => {
  const { isLightMode } = useStyles();
  const { t } = useTranslation("common");
  const { toggleTheme } = useStyles();
  const { toggleDevelopmentMode } = useSettings();

  const idToken = useIdToken();
  const initials = getInitials(idToken);
  const environmentLabel: string =
    process.env.NEXT_PUBLIC_ENVIRONMENT_LABEL ?? "";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { merchantIds } = useMerchantUser();
  const hasMultipleMerchants = merchantIds.length > 1;

  const { isSuccess, data: dadJoke } = useDadJoke();

  return (
    <>
      {/* This bit seems arbitrary, but pushes the content down from behind the floating header */}
      <Box sx={{ height: "64px" }} />
      <AppBarCustomized
        elevation={1}
        id="header-container"
        sx={{
          backgroundColor: isLightMode
            ? `${colors.PURE_WHITE} !important`
            : `${colors.BLACK} !important`,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <Link href="/">
              <Typography
                variant="h2"
                component="div"
                mb="0"
                style={{
                  color: isLightMode ? colors.BLACK : colors.PURE_WHITE,
                }}
              >
                {t("appName")}
              </Typography>
            </Link>

            {idToken ? (
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {environmentLabel && environmentLabel !== "Production" && (
                  <Alert
                    icon={false}
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      backgroundColor:
                        process.env.NEXT_PUBLIC_ENVIRONMENT_LABEL_COLOR,
                      marginRight: "15px",
                      userSelect: "none",
                    }}
                    onClick={() => {
                      toggleDevelopmentMode();
                      toggleTheme();
                    }}
                    title={isSuccess && dadJoke?.joke ? dadJoke.joke : ""}
                  >
                    {environmentLabel}
                  </Alert>
                )}

                <Image src={zipLogo} alt="Zip logo" />

                <Button variant="text" onClick={handleClick}>
                  <Gravatar email={idToken?.email} onClick={() => handleClick}>
                    { initials }
                  </Gravatar>
                </Button>

                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <UserInfoMenuItem />
                  {hasMultipleMerchants && <Divider />}
                  {hasMultipleMerchants && <MerchantSwitcherMenu />}
                  {hasMultipleMerchants && <Divider />}
                  <LogoutMenuItem />
                </Menu>
              </Box>
            ) : (
              <LogoutMenuItem />
            )}
          </Toolbar>
        </Container>
      </AppBarCustomized>
    </>
  );
};
