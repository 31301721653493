import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import React, {
  FunctionComponent,
  CSSProperties,
  HTMLInputTypeAttribute,
  ReactNode,
} from "react";
import { Control, Controller } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
} from "@mui/material";
import { colors } from "../themes/colors";

export type OptionSelect = {
  value: string | number | boolean | null;
  text: string;
  icon?: ReactNode;
};

export type InputMode =
  | "text"
  | "search"
  | "none"
  | "email"
  | "tel"
  | "url"
  | "numeric"
  | "decimal";

export interface FieldFormProps {
  // TODO: fix any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  nameField: string;
  typeField?: HTMLInputTypeAttribute;
  element:
    | "input"
    | "switch"
    | "select"
    | "checkbox"
    | "radiogroup"
    | "buttongroup";
  options?: OptionSelect[];
  disabled?: boolean;
  label?: string;
  adornment?: {
    position: "start" | "end";
    symbol: string;
  };
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  helptext?: string;
  color?: "primary" | "error";
  styleProps?: { [key: string]: CSSProperties };
  inputMode?: InputMode;
}

export const FieldForm: FunctionComponent<FieldFormProps> = ({
  control,
  nameField,
  typeField = "text",
  options = [],
  element,
  disabled = false,
  label = "",
  adornment,
  placeholder,
  multiline = false,
  rows,
  helptext = "  ",
  color = "primary",
  styleProps = {},
  inputMode,
}) => {
  const textFieldStyles = {
    border: `1px solid ${colors.BORDER_GREY}`,
  };

  switch (element) {
    case "input":
      return (
        <Controller
          name={nameField}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth size="small" margin="dense">
              <TextField
                inputProps={{
                  "data-testid": nameField,
                  inputMode: inputMode,
                }}
                InputProps={{
                  startAdornment:
                    adornment?.position === "start" ? (
                      <InputAdornment position="start">
                        {adornment.symbol}
                      </InputAdornment>
                    ) : null,
                  endAdornment:
                    adornment?.position === "end" ? (
                      <InputAdornment position="end">
                        {adornment.symbol}
                      </InputAdornment>
                    ) : null,
                  style: textFieldStyles,
                }}
                {...field}
                size="small"
                type={typeField}
                error={!!error}
                helperText={error?.message ?? helptext}
                disabled={disabled}
                label={label}
                multiline={multiline}
                minRows={multiline ? 3 : undefined}
                rows={rows}
                placeholder={placeholder}
                sx={styleProps}
              />
            </FormControl>
          )}
        />
      );
    case "switch":
      return (
        <Controller
          name={nameField}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth size="small" margin="dense">
              <FormControlLabel
                control={
                  <Switch
                    data-testid={nameField}
                    {...field}
                    disabled={disabled}
                    size="small"
                    checked={field.value ?? false}
                    color={color}
                  />
                }
                label={
                  <div style={{ overflowWrap: "anywhere" }}>
                    <Typography
                      variant="caption"
                      color="primary"
                      display="block"
                      fontSize="14px"
                    >
                      {label}
                    </Typography>
                  </div>
                }
              />
              <FormHelperText>{error?.message ?? "  "}</FormHelperText>
            </FormControl>
          )}
        />
      );
    case "select":
      return (
        <Controller
          name={nameField}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <TextField
                select
                fullWidth
                inputProps={{
                  "data-testid": nameField,
                }}
                InputProps={{
                  style: textFieldStyles,
                }}
                size="small"
                margin="dense"
                {...field}
                error={!!error}
                helperText={error?.message ?? helptext}
                disabled={disabled}
                label={label}
                placeholder={placeholder}
                sx={styleProps}
              >
                {options.map((option: OptionSelect, index) => (
                  // TODO: fix any
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  <MenuItem key={index} value={option.value as any}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          )}
        />
      );
    case "checkbox":
      return (
        <Controller
          data-testid={nameField}
          name={nameField}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} />}
              checked={field.value}
              label={<span style={{ fontWeight: "bold" }}>{label}</span>}
              disabled={disabled}
            />
          )}
        />
      );
    case "radiogroup":
      return (
        <Controller
          name={nameField}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth size="small" margin="dense" error={!!error}>
              <RadioGroup
                {...field}
                row={false}
                onChange={(_, value) => field.onChange(value)}
                value={field.value}
              >
                {options.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={<Radio />}
                    label={option.text}
                    disabled={disabled}
                  />
                ))}
              </RadioGroup>
              {!!error && <FormHelperText>{error?.message}</FormHelperText>}
            </FormControl>
          )}
        />
      );
    case "buttongroup":
      return (
        <Controller
          name={nameField}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth size="small" margin="dense" error={!!error}>
              <ToggleButtonGroup
                {...field}
                onChange={(_, value) => field.onChange(value)}
                value={field.value}
                fullWidth={true}
                exclusive={true}
                color="primary"
              >
                {options.map((option, index) => (
                  <ToggleButton
                    key={index}
                    value={option.value === null ? 0 : option.value}
                    aria-label={option.text}
                    disabled={disabled}
                  >
                    {option.icon}
                    {option.text}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              {!!error && <FormHelperText>{error?.message}</FormHelperText>}
            </FormControl>
          )}
        />
      );

    default:
      return <></>;
  }
};
