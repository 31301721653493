import { UseQueryResult, useQuery } from "@tanstack/react-query";

interface DadJokeApiResponse {
  id: string;
  joke: string;
  status: number;
}

const EmptyResponse: DadJokeApiResponse = {
  id: "",
  joke: "",
  status: 0,
};

const getDadJoke = async (): Promise<DadJokeApiResponse> => {
  if (process.env.NEXT_PUBLIC_ENV !== "development") {
    return EmptyResponse;
  }

  const response = await fetch("https://icanhazdadjoke.com/", {
    headers: {
      Accept: "application/json",
    },
  });
  return response.json();
};

export const useDadJoke = (): UseQueryResult<DadJokeApiResponse, Error> => {
  return useQuery<unknown, Error, DadJokeApiResponse>({
    queryKey: ["get-dad-joke"],
    queryFn: () => getDadJoke(),
    refetchOnMount: false,
  });
};
