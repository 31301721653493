import React, { PropsWithChildren, useContext, useMemo } from "react";
import { LocalStorageContext } from "./LocalStorageContext";
import { MerchantId } from "../../models/merchant";
import { UserContext } from "../user/UserContext";
import {
  getItemForMerchantUser,
  removeItemForMerchantUser,
  setItemForMerchantUser,
} from "../../utils";

interface LocalStorageProviderProps {
  merchantId: MerchantId | undefined;
}

export const LocalStorageProvider = ({
  merchantId,
  children,
}: PropsWithChildren<LocalStorageProviderProps>) => {
  const { user } = useContext(UserContext);
  const auth0User = user?.sub;

  const localStorageValue = useMemo(() => {
    return {
      localStorageGet: (key: string) =>
        getItemForMerchantUser(auth0User, merchantId, key),
      localStorageSet: (key: string, value: string) =>
        setItemForMerchantUser(auth0User, merchantId, key, value),
      localStorageRemove: (key: string) =>
        removeItemForMerchantUser(auth0User, merchantId, key),
    };
  }, [auth0User, merchantId]);

  return (
    <LocalStorageContext.Provider value={localStorageValue}>
      {children}
    </LocalStorageContext.Provider>
  );
};
