import { createContext } from 'react';
import { User, MerchantId } from 'merchant-core';

export type MerchantUserContextState = {
  user: User;
  merchantId: MerchantId;
  merchantIds: MerchantId[];
  setOnboardingMerchantId: (_: MerchantId) => void;
};

export const MerchantUserContext = createContext<MerchantUserContextState>(
  {} as MerchantUserContextState
);