import React, { FunctionComponent } from "react";
import { FieldErrorsImpl, FormState } from "react-hook-form";
import { Box, Typography } from "@mui/material";

export type FormErrorsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState: FormState<any>;
};

export const FormErrors: FunctionComponent<FormErrorsProps> = ({
  formState,
}) => {
  const errorFieldNames = Object.keys(formState?.errors || {});
  if (!errorFieldNames || !errorFieldNames.length) {
    return <></>;
  }

  const getErrorMessages = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors: Partial<FieldErrorsImpl<any>>,
  ): string[] => {
    const messages: string[] = [];

    for (const fieldName of Object.keys(errors || {})) {
      const field = errors[fieldName];
      if (Array.isArray(field)) {
        for (const arrayElement of field) {
          messages.push(...getErrorMessages(arrayElement));
        }
      } else {
        messages.push(field?.message?.toString() || "");
      }
    }

    return messages;
  };

  const messages = getErrorMessages(formState.errors);

  return (
    <Box mb="24px">
      {messages.map((m) => (
        <Typography key={m} color="red">
          {m}
        </Typography>
      ))}
    </Box>
  );
};
