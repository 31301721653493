import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useMerchantUser } from "../../hooks/useMerchantUser";
import { SegmentContext } from "./SegmentContext";
import { usePathname } from "next/navigation";
import { useOnboardingMerchant } from "../../hooks/api/useOnboardingMerchant";

export const SegmentProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const { merchantId } = useMerchantUser();
  const { data: merchant } = useOnboardingMerchant();
  const pathname = usePathname();  

  const writeKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || "";

  // configuration
  const analytics = useMemo(
    () =>
      writeKey
        ? AnalyticsBrowser.load({
            writeKey: writeKey,
          })
        : null,
    [writeKey],
  );

  // identification
  useEffect(() => {
    const identification = async () => {
      if (!analytics) {
        return;
      }

      try {
        if (merchantId) {
          await analytics.identify(merchantId);
        } else {
          // check if we have a user, reset
          // there is still an 'anonymous id' that will be tracked however
          const user = await analytics.user();
          if (user.id()) {
            await analytics.reset();
          }
        }
      } catch {
        // don't care if this fails
      }
    };
    identification();
  }, [merchantId, analytics]);

  // page view
  useEffect(() => {
    if (!analytics) {
      return;
    }

    analytics.page(pathname || "");
  }, [analytics, pathname]);  

  // logging contexts
  const businessInfo = merchant?.businessInfo;
  useEffect(() => {
    if (!analytics || !merchantId) {
      return;
    }

    analytics?.group({
      merchantId,
      merchantTier: businessInfo?.merchantTier,
      businessType: businessInfo?.businessType,
      annualRevenue: businessInfo?.annualRevenue,
      averageOrderValue: businessInfo?.averageOrderValue,
      industry: businessInfo?.industry,
      secondaryIndustry: businessInfo?.secondaryIndustry,
    });
  }, [analytics, merchantId, businessInfo]);

  return (
    <SegmentContext.Provider value={analytics}>
      {children}
    </SegmentContext.Provider>
  );
};
