import { useContext } from "react";
import {
  DebugLogContext,
  DebugLogger,
} from "../contexts/logging/DebugLogContext";

export const useDebugLogger = (): DebugLogger => {
  const logger = useContext(DebugLogContext);
  if (!logger) {
    throw new Error(
      "Attempted to retrieve debug logger without a context. Ensure DebugLogProvider has been applied.",
    );
  }

  return logger;
};
