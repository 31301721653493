import { useCallback, useEffect, useState } from "react";
import { optimizelyClient } from "./optimizelyClient";

export type Decisions = "on" | "off" | "none";

type Flag = {
  isEnabled: boolean;
  isDecisioned: boolean;
}

// As of now, this hook is only for feature flag toggling. Not targeted delivery or experiments.
export const useOptimizelyFeature = (
  flagKey: string,
  userId = "",
  analyticsCallback?: (flagKey: string, decision: Decisions) => void
) => {
  const [flag, setFlag] = useState<Flag>({
    isEnabled: false,
    isDecisioned: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const getStatusOnOff = useCallback((isEnabled: boolean) => {
    return isEnabled ? "on" : "off";
  }, []);

  const getFeature = useCallback(async () => {
    const isEnabled = optimizelyClient.isFeatureEnabled(flagKey);
    setFlag({
      isEnabled,
      isDecisioned: true,
    });

    setIsLoading(false);

    if (analyticsCallback) {
      analyticsCallback(flagKey, !userId ? "none" : getStatusOnOff(isEnabled));
    }
  }, [flagKey, userId]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    const checkReady = () => {
      if (optimizelyClient.isReady()) {
        setIsReady(true);
      } else {
        timeout = setTimeout(checkReady, 100);
      }

      return () => {
        if (timeout) clearTimeout(timeout);
      };
    };

    checkReady();
  }, []);

  useEffect(() => {
    if (isReady) {
      getFeature();
    }
  }, [isReady, getFeature]);

  return {
    flag,
    isLoading
  }
};
