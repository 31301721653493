import React, { FunctionComponent, useContext, useMemo } from "react";
import { TimeZoneContext } from "../contexts";
import { MenuItem } from "@mui/material";
import { useTranslation } from "next-i18next";
import { getOffsetForTimezone } from "../utils/timezone";
import { StyledTextField, TextFieldEvent } from "./StyledTextField";

export const TimeZoneSelector: FunctionComponent = () => {
  const { supportedIanaTimeZones, timeZoneId, setTimeZone } =
    useContext(TimeZoneContext);
  const { t } = useTranslation("common");

  const timeZoneOptions = useMemo(() => {
    const timeZoneTranslations = t("timezone", {
      returnObjects: true,
    }) as never;

    return supportedIanaTimeZones.map((timeZoneId) => {
      const offset = getOffsetForTimezone(timeZoneId);
      const translation = !!timeZoneTranslations[timeZoneId]
        ? t(timeZoneTranslations[timeZoneId], {
            offset,
          })
        : `${timeZoneId} (UTC ${offset})`;
      return (
        <MenuItem key={timeZoneId} value={timeZoneId}>
          {translation}
        </MenuItem>
      );
    });
  }, [supportedIanaTimeZones, t]);

  if (!supportedIanaTimeZones?.length) {
    // No Timezone selector, as no timezones available in context
    return <></>;
  }

  return (
    <StyledTextField
      select
      label={t("timezone-label")}
      value={timeZoneId}
      onChange={(e: TextFieldEvent) => setTimeZone(e.target.value)}
    >
      {timeZoneOptions}
    </StyledTextField>
  );
};
