import { useContext } from "react";
import { StyleOperations, StyleState, StylesContext } from "../contexts/styles/StylesContext";

export const useStyles = (): StyleState & StyleOperations => {
  const context = useContext(StylesContext);
  if (!context) {
    throw new Error("Attempted to retrieve styles without a context. Ensure StylesProvider has been applied.");
  }

  return {
    ...context.state,
    toggleTheme: context.toggleTheme,
  };
};
