import React, { PropsWithChildren, useCallback, useMemo, useReducer } from "react";
import { SettingsContext, SettingsState } from "./SettingsContext";
import { settingsReducer } from "./SettingsReducer";

export const INITIAL_STATE: SettingsState = {
  isDevelopmentMode: false,
};

export const SettingsProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [state, dispatch] = useReducer(settingsReducer, INITIAL_STATE);

  const toggleDevelopmentMode = useCallback(() => {
    dispatch({ type: "TOGGLE_DEVELOPMENT_MODE" });
  }, []);

  const settingsValue = useMemo(() => {
    return {
      isDevelopmentMode: state.isDevelopmentMode,
      toggleDevelopmentMode: toggleDevelopmentMode,
    };
  }, [state, toggleDevelopmentMode]);

  return (
    <SettingsContext.Provider value={settingsValue}>
      {children}
    </SettingsContext.Provider>
  );
};
