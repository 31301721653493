import { ThemeOptions } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { colors } from "merchant-core";

const typographyOverride: TypographyOptions = {
  fontSize: 18,
  htmlFontSize: 18,
  fontFamily: "SharpGroteskBook20,Cooper,arial",
  h1: {
    fontFamily: "SharpGroteskSemiBold25",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "36px",
    marginBottom: "24px",
  },
  h2: {
    fontFamily: "SharpGroteskSemiBold25",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "30px",
    marginBottom: "12px",
  },
  h3: {
    fontFamily: "SharpGroteskSemiBold25",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
  },
  h4: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "22px",
  },
  h5: {
    fontWeight: 700,
  },
  h6: {
    fontSize: 18,
    fontWeight: 700,
  },
  body1: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  body2: {
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
  },
  button: {
    textTransform: "none",
  },
};

export const onboardingHubTheme: ThemeOptions = {
  typography: {
    ...typographyOverride,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
          fontWeight: "bold",
        },
        contained: {
          backgroundColor: colors.PURPLE_MEDIUM,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.8rem",
          backgroundColor: colors.PURPLE_LIGHTEST,
          color: colors.BLACK,
          padding: "1rem",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ...typographyOverride,
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 0,
          marginBottom: 0,
        },
        marginDense: {
          marginTop: 0,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginTop: "16px",
          marginBottom: "24px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: "8px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderBottom: "none !important",
          position: "relative",
          "&:after": {
            borderBottom: `1px solid ${colors.BORDER_GREY}`,
            position: "absolute",
            content: "' '",
            bottom: 0,
            left: "10px",
            right: "10px",
            height: "1px",
          },
          "&:last-child:after": {
            borderBottom: "none !important",
          }
        }
      }
    }
  },
};
