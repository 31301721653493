import { useCallback } from "react";
import { useDatadog } from "./useDatadog";
import { useSegment } from "./useSegment";
import { EventName } from "../constants/events";
import { useDebugLogger } from "./useDebugLogger";

/* eslint-disable @typescript-eslint/no-unused-vars, no-unused-vars */
export interface AnalyticsTracking {
  publishEvent: (
    eventName: EventName,
    properties?: Record<string, unknown>,
  ) => Promise<void>;
  trackError: (message: string, context: unknown) => Promise<void>;
  clearAnalyticsSession: () => Promise<void>;
}

/**
 * Helper function to ensure an argument is non-null and the resulting function doesn't throw any exceptions.
 * Used by analytics, which are nice to have but shouldn't break the main site if something goes wrong.
 * @param Provider The object to confirm is non-null before continuing
 * @param func The function to apply to the provider argument, and wrap
 */
async function dontThrow<
  T,
  F extends (_: T) => void | Promise<void> | Promise<unknown>,
>(provider: T | null, func: F): Promise<unknown> {
  if (!provider) {
    return;
  }

  try {
    return await func(provider);
  } catch (ex) {
    console.error("In tracking analytics", ex, provider);
  }
}

export const useTracking = (): AnalyticsTracking => {
  const logger = useDebugLogger();
  const datadog = useDatadog();
  const segment = useSegment();

  const publishEvent = useCallback(
    async (
      eventName: EventName,
      properties?: Record<string, unknown>,
    ): Promise<void> => {
      logger.log("Publish event", { eventName, properties });

      await Promise.all([
        dontThrow(datadog, (dd) => dd.addAction(eventName, properties)),
        dontThrow(segment, async (s) => await s.track(eventName, properties)),
      ]);
    },
    [logger, datadog, segment],
  );

  const trackError = useCallback(
    async (message: string, context: unknown) => {
      logger.log("error", {
        errorMessage: message,
        context,
      });

      await Promise.all([
        dontThrow(datadog, (dd) =>
          dd.addError(message, {
            context,
          }),
        ),

        dontThrow(
          segment,
          async (s) =>
            await s.track("error", {
              errorMessage: message,
              context,
            }),
        ),
      ]);
    },
    [logger, datadog, segment],
  );

  const clearAnalyticsSession = useCallback(async () => {
    logger.log("Clearing analytics context");

    await Promise.all([
      dontThrow(datadog, (dd) => {
        dd.clearGlobalContext();
        dd.clearUser();
      }),
      dontThrow(segment, async (s) => await s.reset()),
    ]);
  }, [logger, datadog, segment]);

  return {
    publishEvent,
    trackError,
    clearAnalyticsSession,
  };
};
