import { useContext } from "react";
import {
  MerchantUserContextState,
  MerchantUserContext,
} from "../contexts/merchantUser/MerchantUserContext";

export const useMerchantUser = (): MerchantUserContextState => {
  const context = useContext(MerchantUserContext);
  if (!context) {
    throw new Error("Attempted to retrieve merchant user without a context. Ensure MerchantUserProvider has been applied.");
  }

  return context;
};
