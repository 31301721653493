import { IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import React, { FunctionComponent, MouseEvent } from "react";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { StyledTextField } from "./StyledTextField";

export type CopyFieldProps = {
  title: string;
  value?: string;
};

export const CopyField: FunctionComponent<CopyFieldProps> = ({
  title,
  value,
}) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigator.clipboard.writeText(value || "");
  };

  return (
    <Stack spacing={1} direction="column" sx={{ mb: 2 }}>
      <Typography variant="h6" component="label" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <StyledTextField
        variant="outlined"
        margin="dense"
        value={value}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick} aria-label="copy">
                <CopyIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
