import { useContext, useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useTracking } from "./useTracking";
import { UserContext } from "merchant-core";

const SESSION_EXPIRATION_BUFFER_SECONDS = 60; // Auth0's buffer is 60s, we may want more warning.

export interface SessionState {
  accessToken: string;
  sessionExpiringSoon: boolean;
  tokenExpired: boolean;
  refreshAccessToken: () => void;
  logout: () => void;
}

export const useSession = (): SessionState => {
  const { publishEvent, clearAnalyticsSession } = useTracking();
  const context = useContext(UserContext);
  const { logout: authLogout } = useAuth0();
  const [tokenExpiry, setTokenExpiry] = useState<number | undefined>();

  // intended to demonstrate that we're about to expire a token, so display session expiration modal
  const [sessionExpiringSoon, setSessionExpiringSoon] = useState(false);

  // our jwt has expired, need to log out
  const [tokenExpired, setTokenExpired] = useState(false);

  if (!context) {
    throw new Error("Attempted to retrieve state without a context");
  }

  useEffect(() => {
    if (context.idToken) {
      const expiration = context.idToken?.exp;
      setTokenExpiry(expiration);
    }
  }, [context.idToken]);

  useEffect(() => {
    const secondInterval = window.setInterval(() => {
      if (!tokenExpired && !!tokenExpiry) {
        const now = Date.now() / 1000; // now() is in milliseconds, convert to seconds
        const hasExpired = now >= tokenExpiry;
        const isExpiringSoon =
          now - SESSION_EXPIRATION_BUFFER_SECONDS >= tokenExpiry;
        if (tokenExpired !== hasExpired) {
          setTokenExpired(hasExpired);
        }
        if (isExpiringSoon !== sessionExpiringSoon) {
          setSessionExpiringSoon(isExpiringSoon);
        }
      }
    }, 1000);

    return () => window.clearInterval(secondInterval);
  }, [sessionExpiringSoon, tokenExpired, tokenExpiry]);

  useEffect(() => {
    if (tokenExpired) {
      publishEvent("TOKEN_EXPIRED");
    }
  }, [publishEvent, tokenExpired]);

  const logout = useCallback(async () => {
    const { origin, pathname } = window.location;
    const returnUrl = `${origin}/?returnUrl=${pathname}`;
    await publishEvent("LOGOUT");
    await clearAnalyticsSession();
    authLogout({ returnTo: returnUrl });
  }, [authLogout, publishEvent, clearAnalyticsSession]);

  return {
    accessToken: context.accessToken || "",
    sessionExpiringSoon,
    tokenExpired,
    refreshAccessToken: context.refreshAccessToken,
    logout,
  };
};
