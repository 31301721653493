import { createContext } from "react";
import { MerchantId } from "merchant-core";

export type DatadogContextState = {
  merchantId: MerchantId | null;
  isEnabled: boolean;
};

export const DatadogContext = createContext<DatadogContextState>(
  {} as DatadogContextState,
);
