import { NextComponentType, NextPageContext } from "next";
import { ComponentType } from "react";

export type RoutedPageComponentProps = {
  __isPublicRoute?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteComponent = NextComponentType<NextPageContext, any, any>;

export const isPublicRoute = (component: RouteComponent): boolean => {
  if (!component) {
    return false;
  }

  const defaultProps = (component.defaultProps ||
    {}) as RoutedPageComponentProps;
  return !!defaultProps?.__isPublicRoute;
};

export const isPrivateRoute = (component: RouteComponent): boolean => {
  if (!component) {
    return false;
  }

  return !isPublicRoute(component);
};

export const withUnauthenticatedContent = <P extends unknown>(
  Component: ComponentType<P>,
): ComponentType<P> => {
  const defaultProps = Component.defaultProps || {};
  const publicRouteProps = { __isPublicRoute: true };
  Component.defaultProps = {
    ...defaultProps,
    ...publicRouteProps,
  } as unknown as Partial<P>; // gross casting to force this new property in

  return Component;
};
