import { useContext } from "react";
import { IdToken } from "@auth0/auth0-react";
import { User } from "../models/user";
import { UserContext } from "../contexts/user/UserContext";

export const useUser = (): User => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Attempted to retrieve state without a context");
  }

  // Note: This line transforms the user from an Auth0 user object into our own strongly-typed class.  Pleaseensure all User properties are available from the Auth0 user too
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return context.user! as User;
};

export const useIdToken = (): IdToken => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Attempted to retrieve state without a context");
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return context.idToken! as IdToken;
};
