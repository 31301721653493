import React, { useEffect, useState, useMemo, PropsWithChildren } from "react";
import { AxiosContext } from "./AxiosContext";
import { useSession } from "../../hooks/useSession";
import {
  client,
  setRequestInterceptor,
  setResponseInterceptor,
} from "../../api/client";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AxiosProvider = ({ children }: PropsWithChildren) => {
  const { accessToken, logout } = useSession();
  const [interceptorSet, setInterceptorToken] = useState(false);

  useEffect(() => {
    if (accessToken && !interceptorSet) {
      setRequestInterceptor(accessToken, () => setInterceptorToken(true));

      setResponseInterceptor(
        (response) => response,
        (error) => {
          if (error?.response?.status === 401) {
            logout();
          }
          return Promise.reject(error);
        },
      );
    }
  }, [accessToken, interceptorSet, logout]);

  const valueMemo = useMemo(() => {
    return {
      client,
      clientReady: interceptorSet,
    };
  }, [interceptorSet]);

  return (
    <AxiosContext.Provider value={valueMemo}>{children}</AxiosContext.Provider>
  );
};
