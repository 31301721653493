import { BaseSchema, NumberSchema, lazy, number, string } from "yup";
import Lazy from "yup/lib/Lazy";

// Used in help/error text to avoid users providing invalid EIN values,
// in addition to getting them un-stuck regarding formatting.
const exampleEin = "12-3456789";

export const isValidEin = (str?: string): boolean => {
  /*
  This pattern verifies that the first two digits are not in the exclusion list, followed by a
  hyphen, then finally 7 additional digits. There are other considerations, such as an SSN as
  the EIN, which is not currently accepted in our pattern. Additionally, there may be a single
  character suffix that denotes what type of employer they are. That is also not considered in
  this pattern checking.

  https://secure.ssa.gov/apps10/poms.nsf/lnx/0101103015#b
*/
  if (!str) {
    return false;
  }

  return (
    /^(?!(?:07|08|09|17|18|19|28|29|49|69|70|78|79|89|96|97))\d{2}-\d{7}$/.test(
      str,
    ) && str !== exampleEin // also ensuring we don't have the example value provided
  );
};

export const isValidSsn = (str?: string): boolean => {
  /*
  This regex targets a customer specific requirement for a regex that matches US Social security numbers either separated by -(dash), space or nothing.

  So it should match:
  702-02-9921
  702 02 9921
  702029921

  As it may be dangerous to have a regex that matches a sequence of 9 number I made it very strict to reduce the amount of false positives

  So it should not match:
  Telephones, credit cards, same number sequences, 0 sequences, numbers starting with 9, mixed space and dashes
  Source: https://regex101.com/library/kdXrYe
  */
  if (!str) {
    return false;
  }

  const exampleSsn = "123-45-6789";

  return (
    /(?!(\d){3}(-| |)\1{2}\2\1{4})(?!666|000|9\d{2})(\b\d{3}(-| |)(?!00)\d{2}\4(?!0{4})\d{4}\b)/.test(
      str,
    ) && str !== exampleSsn // also ensuring we don't have the example value provided
  );
};

export const isNumeric = (str?: string): boolean => {
  if (!str || Number.isNaN(str)) {
    return false;
  }

  if (/[-]?[\d,]/.test(str)) {
    return true;
  }

  if (typeof str == "number" || Number.isFinite(str)) {
    return true;
  }

  return false;
};

/**
 * Generates a Lazy yup validation object which allows usage of inbuilt yup numeric validation, but returns a nice error message if number is empty or non-numeric
 * @param label The label to apply to this field
 * @param numberSchemaChecks A callback which adds any number schema checks to apply, once the value is confirmed to be the correct format
 * @returns A lazy<BaseSchema> yup validation object
 */
export const requiredNumber = (
  label: string,
  numberSchemaChecks?: (_: NumberSchema) => NumberSchema,
): Lazy<BaseSchema> =>
  lazy((value) => {
    if (value === "" || !isNumeric(value)) {
      return string()
        .required()
        .label(label)
        .test("numeric", "${path} should be numeric", isNumeric);
    }

    const schema = number().required().label(label);

    return numberSchemaChecks !== undefined
      ? numberSchemaChecks(schema)
      : schema;
  });

// no $ prefix, optional commas, optional decimal places
export const currencyRegex =
  /(?=.*?\d)^(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;

// support something like (123) 406-493
// but we're being quite permissive (deliberately)
export const phoneRegex = /^[0-9 ()-]*$/;

export const uppercaseLettersOnly = (str?: string): boolean => {
  if (!str) {
    return false;
  }

  return /^[A-Z]*$/.test(str);
};

export const isAlphaNumeric = (str?: string): boolean => {
  if (!str) {
    return false;
  }

  return /^[a-zA-Z0-9 ]*$/.test(str);
};

const poBoxRegex =
  /^(.*?\d*?)?((p\d{0,2}|p-|p\.|post)\s*((o|o-|o\.|0|of{2}|of{2}ice|b|b-|b\.|box||b0x|bin)|((o|o-|o\.|0|of{2}|of{2}ice)\s*(b|b-|b\.|box|b0x|bin)))\s*|b(ox|in|0x|-|\.)\s*)(#|n|num|number)?\s*\d*?$/i;

export const isPoBox = (str?: string): boolean => {
  if (!str) {
    return false;
  }

  return poBoxRegex.test(str);
};

const usStates = [
  "AL", // "Alabama
  "AK", // "Alaska
  "AZ", // "Arizona
  "AR", // "Arkansas
  "CA", // "California
  "CO", // "Colorado
  "CT", // "Connecticut
  "DE", // "Delaware
  "DC", // "District of Columbia
  "FL", // "Florida
  "GA", // "Georgia
  "HI", // "Hawaii
  "ID", // "Idaho
  "IL", // "Illinois
  "IN", // "Indiana
  "IA", // "Iowa
  "KS", // "Kansas
  "KY", // "Kentucky
  "LA", // "Louisiana
  "ME", // "Maine
  "MD", // "Maryland
  "MA", // "Massachusetts
  "MI", // "Michigan
  "MN", // "Minnesota
  "MS", // "Mississippi
  "MO", // "Missouri
  "MT", // "Montana
  "NE", // "Nebraska
  "NV", // "Nevada
  "NH", // "New Hampshire
  "NJ", // "New Jersey
  "NM", // "New Mexico
  "NY", // "New York
  "NC", // "North Carolina
  "ND", // "North Dakota
  "OH", // "Ohio
  "OK", // "Oklahoma
  "OR", // "Oregon
  "PA", // "Pennsylvania
  "RI", // "Rhode Island
  "SC", // "South Carolina
  "SD", // "South Dakota
  "TN", // "Tennessee
  "TX", // "Texas
  "UT", // "Utah
  "VT", // "Vermont
  "VA", // "Virginia
  "WA", // "Washington
  "WV", // "West Virginia
  "WI", // "Wisconsin
  "WY", // "Wyoming
];

const caProvinces = [
  "AB", // Alberta
  "BC", // British Columbia
  "MB", // Manitoba
  "NB", // New Brunswick
  "NL", // Newfoundland and Labrador
  "NT", // Northwest Territories
  "NS", // Nova Scotia
  "NU", // Nunavut
  "ON", // Ontario
  "PE", // Prince Edward Island
  "QC", // Quebec
  "SK", // Saskatchewan
  "YT", // Yukon Territory
];

export const isValidState = (str?: string): boolean => {
  if (!str) {
    return false;
  }

  const state = str.toUpperCase().trim();

  const territory = process.env.NEXT_PUBLIC_QP_TERRITORY;
  if (territory === "US") {
    return usStates.includes(state);
  }

  if (territory === "CA") {
    return caProvinces.includes(state);
  }

  return false;
};

export const isValidCountry = (str?: string): boolean => {
  if (!str) {
    return false;
  }

  const country = str.toUpperCase().trim();
  return country === process.env.NEXT_PUBLIC_QP_TERRITORY;
};
