import { PropsWithChildren, useCallback, useMemo, useReducer } from "react";
import { themeLight } from "../../themes/themeLight";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import { StyleState, StylesContext } from "./StylesContext";
import { stylesReducer } from "./StylesReducer";

export const INITIAL_STATE: StyleState = {
  mode: "light",
  isLightMode: true,
  theme: themeLight,
};

export const StylesProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [state, dispatch] = useReducer(stylesReducer, INITIAL_STATE);

  const toggleTheme = useCallback(() => {
    dispatch({ type: "TOGGLE_THEME" });
  }, []);

  const stylesValue = useMemo(() => {
    return {
      state,
      toggleTheme,
    }
  }, [state, toggleTheme]);

  return (
    <StylesContext.Provider value={stylesValue}>
      <ThemeProvider theme={state.theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StylesContext.Provider>
  );
};
