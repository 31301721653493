import React, { FunctionComponent, ReactNode, useMemo } from "react";
import Avatar from "@mui/material/Avatar";
import md5 from "md5";

type GravatarProps = {
  email?: string;
  children?: ReactNode;
  onClick?: () => void;
};

export const Gravatar: FunctionComponent<GravatarProps> = ({
  email,
  onClick,
  children,
}) => {
  const gravatarSrc = useMemo(() => {
    const formattedEmail = (email?.toString() ?? "").trim().toLowerCase();
    const hash = md5(formattedEmail);
    const options = new URLSearchParams({
      r: "g", // Rating: G
      s: "30", // Size: 30px
      d: "404", // Default behaviour if no avatar: 404 (falls back to initials)
    });
    return `//gravatar.com/avatar/${hash}?${options}`;
  }, [email]);

  return (
    <Avatar
      sx={{
        bgcolor: "primary.main",
        fontWeight: "bold",
        fontSize: "0.8rem",
        width: 30,
        height: 30,
      }}
      src={gravatarSrc}
      onClick={() => onClick}
    >
      {children || getInitialsFromEmailAddress(email)}
    </Avatar>
  );
};

const getInitialsFromEmailAddress = (email?: string): string =>
  (email ?? "@")
    .split("@")[0]
    .split(".")
    .map((x) => x.charAt(0))
    .join("")
    .toUpperCase();
