import { SettingsState } from "./SettingsContext";

type ModeActions = { type: "TOGGLE_DEVELOPMENT_MODE" };

export const settingsReducer = (state: SettingsState, action: ModeActions): SettingsState => {
  switch (action.type) {
    case "TOGGLE_DEVELOPMENT_MODE":
      return {
        ...state,
        isDevelopmentMode: !state.isDevelopmentMode,
      };
    default:
      return state;
  }
};
