import React, { PropsWithChildren, useEffect } from "react";
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";
import { useMerchantUser } from "../../hooks/useMerchantUser";
import { DatadogContext, DatadogContextState } from "./DatadogContext";
import { usePathname } from "next/navigation";
import { useOnboardingMerchant } from "../../hooks/api/useOnboardingMerchant";

export const DatadogProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const { merchantId } = useMerchantUser();
  const { data: merchant } = useOnboardingMerchant();
  const pathname = usePathname();

  const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID || "";
  const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || "";
  const env = process.env.NEXT_PUBLIC_ENV || "development";
  const version = process.env.NEXT_PUBLIC_VERSION || "0.0.1";

  const isEnabled = !!applicationId.length && !!clientToken.length;

  const contextState: DatadogContextState = {
    merchantId,
    isEnabled,
  };

  // configuration
  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const config: RumInitConfiguration = {
      applicationId: applicationId,
      clientToken: clientToken,
      env: env,
      site: "us3.datadoghq.com",
      service: "merchant-onboarding-hub",
      version: version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
      defaultPrivacyLevel: "mask-user-input",
      silentMultipleInit: true,
    };

    datadogRum.init(config);
    datadogRum.startSessionReplayRecording();
  }, [applicationId, clientToken, version, env, isEnabled]);

  // user identification
  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const user = datadogRum.getUser();
    if (user && user.id !== merchantId) {
      datadogRum.clearUser();
    }
    if (merchantId) {
      datadogRum.setUser({ id: merchantId });
    }
  }, [isEnabled, merchantId]);

  // page views
  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    datadogRum.startView(pathname || "");
  }, [isEnabled, pathname]);  

  // logging contexts
  const businessInfo = merchant?.businessInfo;
  useEffect(() => {
    if (!merchantId) {
      return;
    }

    datadogRum.setGlobalContext({
      merchantId,
      merchantTier: businessInfo?.merchantTier,
      businessType: businessInfo?.businessType,
      annualRevenue: businessInfo?.annualRevenue,
      averageOrderValue: businessInfo?.averageOrderValue,
      industry: businessInfo?.industry,
      secondaryIndustry: businessInfo?.secondaryIndustry,
    });
  }, [merchantId, businessInfo]);

  return (
    <DatadogContext.Provider value={contextState}>
      {children}
    </DatadogContext.Provider>
  );
};