import React, { PropsWithChildren, useEffect, useMemo } from "react";
import { useMerchantUser } from "../../hooks/useMerchantUser";
import { usePathname } from "next/navigation";
import { useOnboardingMerchant } from "../../hooks/api/useOnboardingMerchant";
import { useSettings } from "../../hooks/useSettings";
import { DebugLogContext, consoleLogger, nullLogger } from "./DebugLogContext";

export const DebugLogProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const { isDevelopmentMode } = useSettings();
  const environment = process.env.NEXT_PUBLIC_ENV;
  const debugLoggerEnabled = environment === "development" || isDevelopmentMode;

  const { merchantId } = useMerchantUser();
  const { data: merchant } = useOnboardingMerchant();
  const pathname = usePathname();

  const logger = debugLoggerEnabled ? consoleLogger : nullLogger;
  const logWrapper = useMemo(() => ({ log: logger }), [logger]);

  // identification
  useEffect(() => {
    logger("Identified user with merchant ID", { merchantId: merchantId });
  }, [merchantId, logger]);

  // page view
  useEffect(() => {
    if (!pathname) {
      return;
    }

    logger("Page view", { pathname: pathname });
  }, [logger, pathname]);

  // logging contexts
  const businessInfo = merchant?.businessInfo;
  useEffect(() => {
    if (!merchantId) {
      return;
    }

    logger("Applied logging context", {
      merchantId,
      merchantTier: businessInfo?.merchantTier,
      businessType: businessInfo?.businessType,
      annualRevenue: businessInfo?.annualRevenue,
      averageOrderValue: businessInfo?.averageOrderValue,
      industry: businessInfo?.industry,
      secondaryIndustry: businessInfo?.secondaryIndustry,
    });
  }, [logger, merchantId, businessInfo]);

  return (
    <DebugLogContext.Provider value={logWrapper}>
      {children}
    </DebugLogContext.Provider>
  );
};
