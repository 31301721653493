import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { ClientContext } from "./ClientContext";

export const ClientProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);

  const state = useMemo(() => ({ isClient: isClient }), [isClient]);

  return (
    <ClientContext.Provider value={state}>{children}</ClientContext.Provider>
  );
};
