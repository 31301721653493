import React, { FunctionComponent, ReactNode } from "react";
import { Card, CardHeader, Typography } from "@mui/material";

import styled from "styled-components";
import { colors } from "../themes/colors";

export const StyledOrderCard = styled(Card)`
  border: 1px solid ${colors.BORDER_GREY};
  padding: 1rem;
`;

export const StyledOrderCardHeader: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => (
  <CardHeader
    sx={{
      borderBottom: `1px solid ${colors.BORDER_GREY}`,
    }}
    title={
      <Typography
        variant="h3"
        sx={{
          m: 0,
        }}
      >
        {children}
      </Typography>
    }
  />
);
