import { IdToken } from "@auth0/auth0-react";
import {
  User,
  TerritoryMetadata,
  onboardingClaimName,
  territoryClaimName,
} from "../models/user";
import { MerchantId } from "../models/merchant";

const regexGuid = new RegExp(
  "^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$",
  "i",
);

export const isGuid = (input?: string | null): boolean => {
  if (!input) {
    return false;
  }

  return regexGuid.test(input);
};

function distinctFilter<T>(value: T, index: number, array: T[]): boolean {
  return array.indexOf(value) === index;
}

// retrieves the set of merchant ids for a user based on their JWT
export const getOnboardingMerchantIds = (user: User): MerchantId[] => {
  if (!user) {
    return [];
  }

  const validTerritories = (user[onboardingClaimName] || []).filter(
    (t: TerritoryMetadata) =>
      t.territory == process.env.NEXT_PUBLIC_QP_TERRITORY,
  );

  const merchantIds: MerchantId[] = [];
  if (validTerritories.length) {
    const currentTerritory = validTerritories[0];

    if (currentTerritory.merchant_id) {
      merchantIds.push(currentTerritory.merchant_id);
    }

    if (currentTerritory.merchant_ids && currentTerritory.merchant_ids.length) {
      merchantIds.push(...currentTerritory.merchant_ids);
    }
  }

  // these may have different casing and not distinct, shift lower and make unique
  const loweredIds = merchantIds.map((id) => id.toLowerCase());
  return loweredIds.filter(distinctFilter);
};

// retrieves the set of merchant ids for a user based on their JWT
export const getMerchantIds = (user: User): MerchantId[] => {
  if (!user) {
    return [];
  }

  const validTerritories = (user[territoryClaimName] || []).filter(
    (t: TerritoryMetadata) =>
      t.territory == process.env.NEXT_PUBLIC_QP_TERRITORY,
  );

  const merchantIds: MerchantId[] = [];
  if (validTerritories.length) {
    const currentTerritory = validTerritories[0];

    if (currentTerritory.merchant_id) {
      merchantIds.push(currentTerritory.merchant_id);
    }

    if (currentTerritory.merchant_ids && currentTerritory.merchant_ids.length) {
      merchantIds.push(...currentTerritory.merchant_ids);
    }
  }

  // these may have different casing and not distinct, shift lower and make unique
  const loweredIds = merchantIds.map((id) => id.toLowerCase());
  return loweredIds.filter(distinctFilter);
};

export const getDefaultOnboardingMerchantId = (
  user: User,
): MerchantId | null => {
  if (!user) {
    return null;
  }

  const validTerritories = (user[onboardingClaimName] || []).filter(
    (t: TerritoryMetadata) =>
      t.territory == process.env.NEXT_PUBLIC_QP_TERRITORY,
  );

  if (validTerritories.length) {
    const currentTerritory = validTerritories[0];

    if (currentTerritory.merchant_id) {
      return currentTerritory.merchant_id.toLowerCase();
    } else if (
      currentTerritory.merchant_ids &&
      currentTerritory.merchant_ids.length
    ) {
      return currentTerritory.merchant_ids[0].toLowerCase();
    }
  }

  return null;
};

export const getDefaultMerchantId = (user: User): MerchantId | null => {
  if (!user) {
    return null;
  }

  const validTerritories = (user[territoryClaimName] || []).filter(
    (t: TerritoryMetadata) =>
      t.territory == process.env.NEXT_PUBLIC_QP_TERRITORY,
  );

  if (validTerritories.length) {
    const currentTerritory = validTerritories[0];

    if (currentTerritory.merchant_id) {
      return currentTerritory.merchant_id.toLowerCase();
    } else if (
      currentTerritory.merchant_ids &&
      currentTerritory.merchant_ids.length
    ) {
      return currentTerritory.merchant_ids[0].toLowerCase();
    }
  }

  return null;
};

export const getInitials = (idToken?: IdToken): string => {
  if (!idToken) {
    return "";
  }

  if (idToken.given_name && idToken.family_name) {
    return `${idToken.given_name?.charAt(0).toUpperCase()}${idToken.family_name
      ?.charAt(0)
      .toUpperCase()}`;
  }

  if (idToken.nickname) {
    const initials = idToken.nickname
      .toString()
      .split(".")
      .map((x: string) => x.charAt(0));

    return initials.join("").toUpperCase();
  }

  return "";
};
