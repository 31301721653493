/* eslint-disable no-unused-vars */
enum Colors {
  PURPLE_DARKEST = "#1A0826",
  PURPLE = "#AA8FFF",
  BEIGE = "#FFFFFA",
  PURE_WHITE = "#FFFFFF",
  PURPLE_DARK = "#411361",
  PURPLE_MEDIUM = "#6542BE",
  PURPLE_LIGHT = "#C9B3FF",
  PURPLE_LIGHTER = "#DBCCFF",
  PURPLE_LIGHTEST = "#EDE6FF",
  RED_ERROR = "#B00020",
  GREEN_SUCCESS = "#1F7A39",
  RED_LIGHT_ERROR = "#FEEFEC",
  GREEN_LIGHT_SUCCESS = "#CFF4DB",
  BACKGROUND_GREY = "#F4F4F4",
  BORDER_GREY = "#C6C3C5",
  LABEL_GREY = "#4E4C4E",
  GREY = "#808080",
  BORDER_LIGHT_GREY = "#E9E9E9",
  DISABLED_GREY = "#8C858E",
  PEACH = "#FAE2B9",
  ELECTRIC_GREEN = "#DCF995",
  TAN = "#F3F3E0",
  GRAY_MEDIUM = "#E7E7E7",
  BLACK = "#000000",
  WARN_YELLOW = "#FFA711",
  TOOLTIP_ORANGE = "#FF7C77",
  DISPUTES_GREY = "#786E7A",
}
/* eslint-enable no-unused-vars */

export const colors = Colors;
