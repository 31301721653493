import { MenuItem, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { useMerchantUser } from "../hooks/useMerchantUser";
import Link from "next/link";

export const MerchantSwitcherMenu: FunctionComponent = () => {
  const { merchantId, merchantIds, setOnboardingMerchantId } =
    useMerchantUser();

  return (
    <>
      <Typography m={2}>Select Merchant ID</Typography>
      {merchantIds.map((mid) => {
        const isActive = mid === merchantId;
        const menuItemSuffix = isActive ? " ✔" : "";

        return (
          <MenuItem
            key={mid}
            disabled={isActive}
            href="/"
            onClick={() => setOnboardingMerchantId(mid)}
          >
            <Link href="/" onClick={() => setOnboardingMerchantId(mid)}>
              <Typography fontFamily="monospace">
                {mid}
                {menuItemSuffix}
              </Typography>
            </Link>
          </MenuItem>
        );
      })}
    </>
  );
};
