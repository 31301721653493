import React from "react";
import { OptimizelyProvider } from "@optimizely/react-sdk";
import { optimizelyClient } from "./optimizelyClient";

interface Props {
  children: React.ReactNode;
  userId?: string;
}

export const OptimizelyProviderWrapper: React.FC<Props> = ({
  children,
  userId,
}) => {
  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={userId ? { id: userId } : undefined}
    >
      {children}
    </OptimizelyProvider>
  );
};
