import { isSupportedIanaTimeZone } from "../../utils/timezone";
import { ModeActions, TimeZoneContextState } from "./TimeZoneContext.types";

export const timeZoneReducer = (
  state: TimeZoneContextState,
  action: ModeActions,
): TimeZoneContextState => {
  switch (action.type) {
    case "SET_TIMEZONE":
      const newTimeZoneId = action.payload;
      const isNewTimeZoneSupported = isSupportedIanaTimeZone(
        state.supportedIanaTimeZones,
        newTimeZoneId,
      );
      return {
        ...state,
        timeZoneId: isNewTimeZoneSupported ? newTimeZoneId : state.timeZoneId,
      };
  }
};
