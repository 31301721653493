import { createContext } from 'react';
import { AxiosInstance } from 'axios';

export type AxiosContextProps = {
  client: AxiosInstance,
  clientReady: boolean,
};

export const AxiosContext = createContext<AxiosContextProps>(
  {} as AxiosContextProps
);