import { Theme } from "@mui/material";
import { createContext } from "react";

export interface StyleState {
  mode: string;
  isLightMode: boolean;
  theme: Theme;
}

export interface StyleOperations {
  toggleTheme: () => void;
}

export type StylesContextProps = {
  state: StyleState;
} & StyleOperations;

export const StylesContext = createContext<StylesContextProps>(
  {} as StylesContextProps,
);
