import { Box, Modal } from "@mui/material";
import React, { FunctionComponent, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export type ExternalImageProps = {
  src: string;
  alt?: string;
};

export const ExternalImage: FunctionComponent<ExternalImageProps> = ({
  src,
  alt,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <img
        alt={alt}
        src={src}
        style={{ maxWidth: "100%", maxHeight: "50vh" }}
        onClick={handleOpen}
      />
      <Modal open={open} onClose={handleClose} data-testid="modal">
        <Box sx={style}>
          <img
            alt={alt}
            src={src}
            style={{
              maxWidth: "95vw",
              maxHeight: "95vh",
              objectFit: "contain",
            }}
            onClick={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
};
