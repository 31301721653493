import { Theme, createTheme } from "@mui/material/styles";
import "@emotion/styled";
import { colors } from "merchant-core";
import { onboardingHubTheme } from "./onboardingHub";
import { enUS } from "@mui/x-date-pickers/locales";

export const themeDark: Theme = createTheme({
  ...onboardingHubTheme,
  palette: {
    mode: "dark",
    primary: {
      main: colors.PURE_WHITE.toString(),
    },
    secondary: {
      main: colors.PURPLE_LIGHT.toString(),
    },
    error: {
      main: colors.RED_ERROR.toString(),
    },
    text: {
      primary: colors.PURPLE_LIGHTEST.toString(),
      secondary: colors.PURPLE_MEDIUM.toString(),
      disabled: colors.PURPLE_DARK.toString(),
    },
  },
}, enUS);
